import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/seo'

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <SEO title="404: Not Found" />
        <h1>Not Found</h1>
        <p>There's no such thing on this site yet! If you are kind enough and would like to take it upon you to write such a thing, please feel free to send an email to <a href = "mailto: tyroprogrammer.info@gmail.com">tyroprogrammer.info@gmail.com</a>.</p>
      </Layout>
    )
  }
}

export default NotFoundPage
